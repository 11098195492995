import TypeStrictData, { fieldType } from '@/classes/common/TypeStrictData';

import { getQuestionData, parseTsv } from '@/master';
import cooking from '@/master/cooking/cooking.txt';
import question from '@/master/cooking/question.txt';



/** 料理問題 */
interface CookingQuestion {
  question: QuestionData;
  cookings: CookingData[];
}

/** 枠数情報Enum */
export enum FrameType {
  frame3x2,
  frame4x3,
  frame6x4,
}

/** 料理マスタクラス */
export class CookingData extends TypeStrictData {
  /** 料理No */
  @fieldType(Number)
  public cookingNo = -1;

  /** 料理名 */
  @fieldType(String)
  public cookingName = '';

  /** 工程No一覧（CSV） */
  @fieldType(String)
  public process = '';

  /** 工程No一覧取得 */
  public getProcessNos(): number[] {
    return this.process.split(',').map(no => Number(no));
  }
}

/** 問題マスタクラス */
export class QuestionData extends TypeStrictData {
  /** 料理問題No */
  @fieldType(Number)
  public questionNo = -1;

  /** マス列利用数 */
  @fieldType(Number)
  public colCount = -1;

  /** マス行利用数 */
  @fieldType(Number)
  public rowCount = -1;

  /** マス種別 */
  @fieldType(Number)
  public frameType = -1;

  /** 料理No一覧（CSV） */
  @fieldType(String)
  public cooking = '';

  /** 工程No一覧取得 */
  public getCookingNos(): number[] {
    return this.cooking.split(',').map(no => Number(no));
  }
}

/** 料理マスタデータ一覧 */
const cookingListData = parseTsv(CookingData, cooking);

/** 問題マスタデータ一覧 */
const questionListData = parseTsv(QuestionData, question);

/** 問題取得 */
const getQuestion = (sectionNo: number, unitNo: number): CookingQuestion => {
  const question = getQuestionData(questionListData, sectionNo, unitNo);

  const cookingNos  = question.getCookingNos();
  const tmpCookings = cookingListData.filter(c => cookingNos.includes(c.cookingNo));
  const cookings    = question.getCookingNos().map(no => tmpCookings.find(c => c.cookingNo === no)!); // undefinedは無い想定

  return { question, cookings };
};

export default getQuestion;
