import Vue from 'vue';
import mixins from 'vue-typed-mixins';
import VueDraggableResizable from 'vue-draggable-resizable';
import 'vue-draggable-resizable/dist/VueDraggableResizable.css';
import _ from 'lodash';

import RoutesMixin from '@/user/mixins/RoutesMixin';
import StudyMixin from '@/user/mixins/StudyMixin';
import WindowAdjustMixin from '@/user/mixins/WindowAdjustMixin';

import StudyResultDialog from '@/user/components/study/dialog/result/StudyResultDialog.vue';

import getQuestion, { QuestionData, CookingData, FrameType } from '@/master/cooking';



/** 回答欄 */
interface AnswerBox {
  process: { processNo: number, rowNum: number };
  answer?: { cookingNo: number, itemIndex: number };
}

/** 回答欄枠数情報 */
interface AnswerBoxFrame {
  colCount: number;
  rowCount: number;
}

/** ドラッグアイテム */
interface DragItem {
  cookingNo: number;
  initX: number;
  initY: number;
  x: number;
  y: number;
  w: number;
  h: number;
  isDragging: boolean;
}

/** 回答欄矩形情報 */
interface AnswerBoxRect {
  top: number;
  left: number;
  bottom: number;
  right: number;
}

/** 表示用作り方情報 */
interface DisplayCookingProcess {
  cookingNo: number | null;
  processNos: (number | null)[];
}

/** 入力補助情報 */
interface AssitanceTarget {
  cookingNo: number;
  processNo: number;
  answerBoxIndex: number;
}

/** 入力補助矩形情報 */
interface AssistanceRect {
  deg: number;
  top: number;
  left: number;
  width: number;
  padding: number;
}

/** 入力補助 */
interface Assistance {
  target: AssitanceTarget;
  rect: AssistanceRect;
}

/** チュートリアル情報 */
type TutorialData = {
  sectionNo: number;
  unitNo: number;
  assitanceTargets: AssitanceTarget[];
};

/** 作り方表示欄数 */
const COOKING_BOX_COUNT = 4;

/** チュートリアル情報 */
const TUTORIAL_DATA: TutorialData[] = [
  {
    sectionNo: 1,
    unitNo: 1,
    assitanceTargets: [
      { cookingNo: 1, processNo: 1, answerBoxIndex: 0 }
    ]
  },
  {
    sectionNo: 1,
    unitNo: 2,
    assitanceTargets: [
      { cookingNo: 1, processNo: 1, answerBoxIndex: 0 },
      { cookingNo: 3, processNo: 3, answerBoxIndex: 1 },
    ]
  },
  {
    sectionNo: 1,
    unitNo: 3,
    assitanceTargets: [
      { cookingNo: 6, processNo: 2, answerBoxIndex: 0 },
      { cookingNo: 6, processNo: 3, answerBoxIndex: 3 },
    ]
  },
];

export default mixins(RoutesMixin, StudyMixin, WindowAdjustMixin).extend({

  name: 'Cooking',

  components: {
    VueDraggableResizable,
    StudyResultDialog
  },

  data(): {
    question: QuestionData | null;
    cookings: CookingData[];
    processNos: number[];
    items: DragItem[];
    answerBoxes: AnswerBox[];
    answerBoxFrame: AnswerBoxFrame;
    answerBoxRects: AnswerBoxRect[];
    cellStyle: object;
    cellImgStyle: object;
    assistance: Assistance | null;
    isWaitFlashAnimate: boolean;
  } {
    return {
      question: null,
      cookings: [],
      processNos: [],
      items: [],
      answerBoxFrame: {
        rowCount: 0,
        colCount: 0
      },
      answerBoxes: [],
      answerBoxRects: [],
      cellStyle: {},
      cellImgStyle: {},
      assistance: null,
      isWaitFlashAnimate: true
    };
  },

  computed: {
    enableColCount(): number {
      return this.question !== null ? this.question.colCount : 0;
    },
    enableRowCount(): number {
      return this.question !== null ? this.question.rowCount : 0;
    },
    frameType(): number {
      return this.question !== null ? this.question.frameType : 0;
    },
    displayCookingProcesses(): DisplayCookingProcess[] {
      const ret: DisplayCookingProcess[] = [];
      const processCount = this.frameType > FrameType.frame3x2 ? 3 : 2;
      const cookings: (CookingData | null)[] = new Array(COOKING_BOX_COUNT).fill(null);
      Object.assign(cookings, this.cookings);
      cookings.forEach(c => {
        const processNos: (number | null)[] = new Array(processCount).fill(null);
        if (c !== null) Object.assign(processNos, c.getProcessNos());
        ret.push({
          cookingNo: c !== null ? c.cookingNo : null,
          processNos: processNos
        });
      });
      return ret;
    },
    bgStyle(): object {
      const direction = this.isPortrait ? 'v' : 'h';
      return {
        'background-image': `url(${require(`@/assets/img/study/cooking/cooking_${this.frameType}_${direction}_bg.svg`)})`
      };
    },
    isTutorial(): boolean {
      return TUTORIAL_DATA.some(data => data.sectionNo === this.sectionNo && data.unitNo === this.unitNo);
    },
    assitanceTargets(): AssitanceTarget[] {
      const data = TUTORIAL_DATA.find(data => data.sectionNo === this.sectionNo && data.unitNo === this.unitNo);
      return data !== undefined ? data.assitanceTargets : [];
    },
    assistanceStyle(): object {
      if (this.assistance === null) return {};
      return {
        '-webkit-transform': `rotate(${this.assistance.rect.deg}deg)`,
        transform: `rotate(${this.assistance.rect.deg}deg)`,
        top: `${this.assistance.rect.top}px`,
        left: `${this.assistance.rect.left}px`,
        width: `${this.assistance.rect.width}px`,
        'padding-left': `${this.assistance.rect.padding}px`,
      };
    },
  },

  filters: {
    cookingImgSrc(cookingNo: number): string {
      return require(`@/assets/img/study/cooking/c${('000' + cookingNo).slice(-3)}.png`);
    },
    processImgSrc(processNo: number): string {
      return require(`@/assets/img/study/cooking/p${('000' + processNo).slice(-3)}.png`);
    }
  },

  created(): void {
    this.createData();
    this.initializeStudyVariable();
    this.initializeStudyRequest();
  },

  mounted(): void {
    this.onChangeWindowSizeHandler();
    this.startTimer();
  },

  destroyed(): void {
    this.clearRestTimeInterval();
    this.clearStudyResult();
  },

  methods: {
    createData(): void {
      // 問題取得
      const cookingQuestion = getQuestion(this.sectionNo, this.unitNo);

      // 問題生成
      this.question   = cookingQuestion.question;
      this.cookings   = cookingQuestion.cookings;
      this.processNos = [];
      this.items      = [];
      this.cookings.forEach(c => {
        c.getProcessNos().forEach(no => {
          this.processNos.push(no);
          this.items.push({
            cookingNo: c.cookingNo,
            initX: 0,
            initY: 0,
            x: 0,
            y: 0,
            w: 1,
            h: 1,
            isDragging: false
          });
        });
      });
      // 工程Noの重複削除
      this.processNos = _.uniq(this.processNos);
      // チュートリアル以外は工程Noをシャッフル
      if (!this.isTutorial) this.processNos = _.shuffle(this.processNos);

      // 回答枠数
      switch (this.question.frameType) {
        case FrameType.frame3x2:
          this.answerBoxFrame = { colCount: 3, rowCount: 2 };
          break;
        case FrameType.frame4x3:
          this.answerBoxFrame = { colCount: 4, rowCount: 3 };
          break;
        case FrameType.frame6x4:
          this.answerBoxFrame = { colCount: 6, rowCount: 4 };
          break;
        default:
          break;
      }

      // 工程数とマスタの列数が一致しない場合はアラート表示してから例外をthrow
      if (this.processNos.length !== this.question.colCount) {
        alert('マスタエラー');
        throw new Error('illigal process count');
      }

      // 回答欄
      this.answerBoxes = [];
      for (let i = 0; i < this.answerBoxFrame.rowCount; i++) {
        this.processNos.forEach(no => {
          this.answerBoxes.push({
            process: { processNo: no, rowNum: i + 1 }
          });
        });
      }
    },
    cookingImgBoxCssClass(cookingNo: number, processNo?: number): object {
      const cNo       = this.assistance?.target.cookingNo;
      const pNo       = this.assistance?.target.processNo;
      const boxClass  = `cookingImgBox${this.frameType}`;
      const isDisable = cookingNo === null || processNo === null;
      const isFlash   = cookingNo === cNo && (processNo === undefined || processNo === pNo);
      return {
        [boxClass]:           true,
        disableCookingImgBox: isDisable,
        flashCookingImgBox:   isFlash && !this.isWaitFlashAnimate
      };
    },
    onDragging(index: number): void {
      if (this.items[index].isDragging) return;
      this.items[index].isDragging = true;
    },
    async onDragstop(left: number, top: number, index: number): Promise<void> {
      const item = this.items[index];

      // ドラッグ状態を戻す
      item.isDragging = false;

      // xyの値が変更されないと位置調整しても位置が更新されないので
      // 一度ドラッグ位置に配置して明示的に更新をかける
      item.x = left;
      item.y = top;
      await Vue.nextTick();

      // 自身が回答にある場合は一度取り除く
      const own = this.answerBoxes.find(ab => ab.answer !== undefined && ab.answer.itemIndex === index);
      if (own !== undefined && own.answer !== undefined) delete own.answer;

      // 配置位置チェック
      let isHit = false;
      const x = left + item.w / 2;
      const y = top  + item.h / 2;
      for (let i = 0; i < this.answerBoxRects.length; i++) {
        const rect = this.answerBoxRects[i];
        isHit = rect.left < x && x < rect.right && rect.top < y && y < rect.bottom;
        if (isHit) {
          // 位置調整
          item.x = (rect.left + rect.right - item.w) / 2;
          item.y = (rect.top + rect.bottom - item.h) / 2;
          // 回答が入っていた場合は入っていた方を元の位置に戻す
          const target = this.answerBoxes[i];
          if (target.answer !== undefined) this.$_resetItemPosition(target.answer.itemIndex);
          // 回答を格納してbreak
          target.answer = { cookingNo: item.cookingNo, itemIndex: index };
          break;
        }
      }
      // どの回答欄にも入らない場合は元の位置に戻す
      if (!isHit) this.$_resetItemPosition(index);

      // チュートリアルの場合は入力補助設定
      this.$_setAssistance();

      // 全部配置が終わったら正誤判定
      const answeredCount = this.answerBoxes.filter(ab => ab.answer !== undefined).length;
      if (answeredCount === this.items.length) {
        const isCorrect = this.$_judge();
        this.judgeScoreResult(isCorrect);
      }
    },
    // WindowAdjustMixin上書き
    async onChangeWindowSizeHandler(): Promise<void> {
      await this.$_calcTimetableCellStyle();
      this.$_calcAnswerBoxRects();
      this.$_calcItemSizePosition();
      // チュートリアルの場合は入力補助設定
      this.$_setAssistance();
      // vue-draggable-resizableのparentサイズが変わらないので明示的にイベント通知
      window.dispatchEvent(new Event('resize'));
    },
    // 正誤判定と結果返却
    $_judge(): boolean {
      for (const cooking of this.cookings) {
        const judgedProcesses: number[] = [];
        let currentRowNum = 0;
        for (const ab of this.answerBoxes) {
          if (ab.answer?.cookingNo === cooking.cookingNo) {
            if (ab.process.rowNum === currentRowNum) return false;
            judgedProcesses.push(ab.process.processNo);
            currentRowNum = ab.process.rowNum;
          }
        }
        if (!_.isEqual(judgedProcesses, cooking.getProcessNos())) return false;
      }
      return true;
    },
    async $_calcTimetableCellStyle(): Promise<void> {
      const baseWidth  = this.calculateRatio(this.isPortrait ? 380 : 460);
      const baseHeight = this.calculateRatio(this.isPortrait ? 234 : 223);
      const width      = baseWidth / (this.answerBoxFrame.colCount + 1);
      const height     = baseHeight / (this.answerBoxFrame.rowCount + 1);
      this.cellStyle = {
        width: `${width}px`,
        height: `${height}px`
      };
      this.cellImgStyle = {
        height: `${ height * 0.7 }px`
      };
      await Vue.nextTick(); // 後続の処理で変更が受け取れるように描画更新しておく
    },
    $_calcAnswerBoxRects(): void {
      this.answerBoxRects = [];
      const elements = this.$refs.answerBoxes as HTMLElement[];
      if (elements === undefined || elements.length === 0) return; // 回答欄が存在しない場合はreturn

      const parent = elements[0].offsetParent as HTMLElement;
      for (let i = 0; i < elements.length; i++) {
        const answerElm = elements[i];
        const top  = parent.offsetTop + answerElm.offsetTop;
        const left = parent.offsetLeft + answerElm.offsetLeft;
        this.answerBoxRects.push({
          top:    top,
          left:   left,
          bottom: top + answerElm.offsetHeight,
          right:  left + answerElm.offsetWidth,
        });
      }
    },
    $_calcItemSizePosition(): void {
      const itemCount    = this.items.length;
      const baseBoxWidth = this.calculateRatio(this.isPortrait ? 360 : 300);
      const boxLeft      = this.calculateRatio(this.isPortrait ? 10 : 170);
      const boxTop       = this.calculateRatio(this.isPortrait ? 473 : 277);
      const boxWidth     = this.calculateRatio(this.isPortrait ? 380 : 460);
      const boxHeight    = this.calculateRatio(this.isPortrait ? 105 : 64);

      // 回答枠組み毎の設定
      let maxItemWH       = 1;
      let hasTwiceRow     = false;
      let rowMaxItemCount = itemCount;
      switch (this.frameType) {
        case FrameType.frame3x2:
          maxItemWH       = this.calculateRatio(70);
          break;
        case FrameType.frame4x3:
          maxItemWH       = this.calculateRatio(56);
          rowMaxItemCount = 4;
          hasTwiceRow     = this.isPortrait && itemCount > rowMaxItemCount;
          break;
        case FrameType.frame6x4:
          maxItemWH       = this.calculateRatio(this.isPortrait ? 40 : 36);
          rowMaxItemCount = 6;
          hasTwiceRow     = itemCount > rowMaxItemCount;
          break;
      }

      // アイテムのサイズ計算と配置
      let itemWH = baseBoxWidth / rowMaxItemCount;
      if (itemWH > maxItemWH) itemWH = maxItemWH;
      let x = 0;
      let y = 0;
      let marginX = 0;
      let marginY = 0;
      if (hasTwiceRow) {
        marginX = (boxWidth - itemWH * rowMaxItemCount) / (rowMaxItemCount + 1);
        marginY = (boxHeight - itemWH * 2) / 4;
      } else {
        marginX = (boxWidth - itemWH * itemCount) / (itemCount + 1);
        marginY = (boxHeight - itemWH) / 2;
      }
      x = boxLeft + marginX;
      y = boxTop + marginY;
      this.items.forEach((item, i) => {
        item.initX = x;
        item.initY = y;
        item.x = x;
        item.y = y;
        item.w = itemWH;
        item.h = itemWH;

        // 回答に入っている場合は位置調整
        const answerIndex = this.answerBoxes.findIndex(ab => ab.answer?.itemIndex === i);
        if (answerIndex > -1) {
          const rect = this.answerBoxRects[answerIndex];
          item.x = (rect.left + rect.right - item.w) / 2;
          item.y = (rect.top + rect.bottom - item.h) / 2;
        }

        x += marginX + itemWH;
        // 2段目に切り替わる場合は位置調整
        if (hasTwiceRow && i + 1 === rowMaxItemCount) {
          x = boxLeft + marginX;
          y += marginY * 2 + itemWH;
        }
      });
    },
    $_resetItemPosition(index: number): void {
      const item = this.items[index];
      item.x = item.initX;
      item.y = item.initY;
    },
    async $_setAssistance(): Promise<void> {
      this.assistance = null;

      // チュートリアル以外はreturn
      if (!this.isTutorial) return;

      // アニメーションの同期を取るために一時停止フラグを立てて止めておく
      this.isWaitFlashAnimate = true;
      await new Promise((resolve) => setTimeout(resolve, 100)); // アニメーション止まるまでの待ち

      const itemIndexes = this.items.map((item, i) => i);
      for (const assitanceTarget of this.assitanceTargets) {
        // 対象回答欄の取得
        const targetBox = this.answerBoxes[assitanceTarget.answerBoxIndex];
        if (targetBox.answer?.cookingNo === assitanceTarget.cookingNo) {
          // 回答欄に入っている料理のIndexを取り除く
          itemIndexes.splice(targetBox.answer.itemIndex, 1);
          continue;
        }
        const targetBoxRect = this.answerBoxRects[assitanceTarget.answerBoxIndex];

        // 対象料理の取得
        if (itemIndexes.length === 0) break; // 対象が無くなった場合はbreak
        const sourceItem = this.items[itemIndexes[0]];

        // 矢印設定
        const sourceX = sourceItem.x + (sourceItem.w / 2);
        const sourceY = sourceItem.y + (sourceItem.w / 2);
        const targetX = (targetBoxRect.left + targetBoxRect.right) / 2;
        const targetY = (targetBoxRect.top + targetBoxRect.bottom) / 2;
        const width   = Math.sqrt(Math.pow(sourceX - targetX, 2) + Math.pow(sourceY - targetY, 2));
        const deg     = Math.atan2(targetY - sourceY, targetX - sourceX) / (Math.PI / 180);
        this.assistance = {
          target: assitanceTarget,
          rect: {
            deg:     deg,
            top:     sourceY,
            left:    sourceX,
            width:   width,
            padding: sourceItem.w / 2
          }
        };
        break;
      }

      // アニメーションの一時停止フラグを戻す
      this.isWaitFlashAnimate = false;
    },
  }

});
